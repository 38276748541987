import * as React from "react"
import { Link } from "gatsby"
import "../css/bootstrap.min.css"
import "../css/style.css"
import "../fonts/fonts.css"

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Careers at Bajaj Electricals</title>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand logo" href="#"><img src="/images/logo.png" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="hamburger">
                    <span />
                  </div>
                </button>
                <div className="collapse navbar-collapse" id="navbarsExample09">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                      <a className="nav-link" href="#">Careers</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Professionals</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Students &amp; Graduates</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Life @ Bajaj Electricals</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <section className="banner-section">
        <figure className="spotlight_img positionRelative">
          <picture>
            <source media="(max-width: 767px)" srcSet="/images/banner-mobile-2.jpg" alt="" width={1920} height={1256} />
            <source media="(max-width: 1920px)" srcSet="/images/banner.jpg" alt="" width={1920} height={1256} />
            <img src="/images/banner.jpg" alt="" width={1920} height={1256} />
          </picture>
        </figure>
        <div className="banner-caption">
          <h4>Do What You</h4>
          <h1>Love</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vel lacus quis.</p>
          <form>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="SEARCH JOBS" />
              <button><img src="/images/search.png" /></button>
            </div>
          </form>
        </div>
      </section>
      
      <section className="card-layout">
        <div className="row m-0">
          <div className="col-sm-7 p-0">
            <div className="row m-0">
              <div className="col-sm-6 p-0">
                <div className="card">
                  <img src="/images/image01.jpg" alt="" width height />
                  <div className="center-div">
                    <a href="javascript:;">
                      <h2>You are offline</h2>
                      <p>Please connect to a network to search for the latest opportunities.</p>
                      <span className="go-btn">
                        <img src="/images/go-arrow.png" alt="" width height />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="clear">
          <div className="top-menu">
            <ul className="text-center">
              <li><a href="javascript:;" title="Consumer Products">Consumer Products</a></li>
              <li><a href="javascript:;" title="Illumination">Illumination</a></li>
              <li><a href="javascript:;" title="EPC">EPC</a></li>
              <li><a href="javascript:;" title="Exports">Exports</a></li>
              <li><a href="javascript:;" title="Events">Events</a></li>
              <li><a href="javascript:;" title="Blog">Blog</a></li>
              <li><a href="javascript:;" title="Site Feedback">Site Feedback</a></li>
            </ul>
          </div>
          <div className="bottom-menu">
            <div className="row align-items-center">
              <div className="col-sm-9">
                <h3 className="toggle-menu">Quick Links</h3>
                <ul className="toggle-list">
                  <li><a href="javascript:;" title="Partners Login" className="pl-0">Partners Login</a></li>
                  <li><a href="javascript:;" title="Privacy">Privacy</a></li>
                  <li><a href="javascript:;" title="Terms and Conditions">Terms and Conditions</a></li>
                  <li><a href="javascript:;" title="Disclaimer">Disclaimer</a></li>
                  <li><a href="javascript:;" title="Testimonials">Testimonials</a></li>
                  <li><a href="javascript:;" title="Mode of Payments" className="border-none">Mode of Payments</a></li>
                </ul>
              </div>
              <div className="col-sm-3">
                <div className="social text-right">
                  <a href="https://www.facebook.com/bajajelectricals" className="facebook" title="Facebook" target="_blanck"><img src="/images/Facebook.png" width={32} height={32} alt="Facebook" /></a>
                  <a href="https://twitter.com/bajajelectrical" className="twitter" title="Twitter" target="_blanck"><img src="/images/Twitter.png" width={32} height={32} alt="Twitter" /></a>
                  <a href="https://www.youtube.com/user/BajajElectricalsABD" className="youtube" title="Youtube" target="_blanck"><img src="/images/Youtube.png" width={32} height={32} alt="Youtube" /></a>
                  <a href="https://www.instagram.com/bajajelectricals_ltd" className="instagram" title="Instagram" target="_blanck"><img src="/images/Instagram.png" width={32} height={32} alt="Instagram" /></a>
                  <a href="https://www.linkedin.com/company/bajajelectricals/" className="twitter" title="linkedin" target="_blanck">
                    <img src="/images/linkedin.png" width={32} height={32} alt="linkedin" target="_blanck" />
                  </a>
                </div>
              </div>                    
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright text-center">
        <p>Copyright © Bajaj Electricals Ltd 2021</p>
      </section>
    </main>
  )
}

export default IndexPage
